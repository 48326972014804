const isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
// 路由配置
const options = {
  mode: 'history',
  routes: [
    {
      path: '*',
      name: '404',
      component:  () => {
        if (isMobile) {
          return import('@/pages/mobile/index/UbiIndex')
        } else {
          return import('@/pages/pc/exception/404')
        }
      }
    },
    {
      path: '/404',
      name: 'exception_404',
      component:  () => {
        if (isMobile) {
          return import('@/pages/mobile/exception/404')
        } else {
          return import('@/pages/pc/exception/404')
        }
      }
    },
    {
      path: '/index',
      name: 'index',
      component: () => {
        if (isMobile) {
          return import('@/pages/mobile/index/UbiIndex')
        } else {
          return import('@/pages/pc/index/UbiIndex')
        }
      },
      meta: {
        title: '优毕网-优质项目库，一站式指导服务'
      }
    },
    {
      path: '/',
      name: 'home',
      component: () => {
        if (isMobile) {
          return import('@/pages/mobile/index/UbiIndex')
        } else {
          return import('@/pages/pc/index/UbiIndex')
        }
      },
      meta: {
        title: '优毕网-优质项目库，一站式指导服务'
      }
    },
    {
      path: '/project/:id',
      name: 'detail',
      component: () => {
        if (isMobile) {
          return import('@/pages/mobile/index/UbiDetail')
        } else {
          return import('@/pages/pc/index/UbiDetail')
        }
      },
      meta: {
        title: '优毕网-优质项目库，一站式指导服务'
      }
    },
    {
      path: '/pay/:id',
      name: 'pay',
      component: () => {
        if (isMobile) {
          return import('@/pages/mobile/index/UbiPay')
        } else {
          return import('@/pages/pc/index/UbiPay')
        }
      },
      meta: {
        title: '优毕网-优质项目库，一站式指导服务'
      }
    },
    {
      path: '/result/:id',
      name: 'result',
      component: () => {
        if (isMobile) {
          return import('@/pages/mobile/index/UbiPayResult')
        } else {
          return import('@/pages/pc/index/UbiPayResult')
        }
      },
      meta: {
        title: '优毕网-优质项目库，一站式指导服务'
      }
    },
    {
      path: '/user',
      name: 'user',
      component: () => {
        if (isMobile) {
          return import('@/pages/mobile/user/UbiUser')
        } else {
          return import('@/pages/pc/user/UbiUser')
        }
      },
      meta: {
        title: '优毕网-优质项目库，一站式指导服务'
      }
    },
    {
      path: '/order',
      name: 'order',
      component: () => {
        if (isMobile) {
          return import('@/pages/mobile/user/UbiOrder')
        } else {
          return import('@/pages/pc/user/UbiUser')
        }
      },
      meta: {
        title: '优毕网-优质项目库，一站式指导服务'
      }
    },
    {
      path: '/order/:id',
      name: 'order',
      component: () => {
        if (isMobile) {
          return import('@/pages/mobile/order/UbiOrderDetail')
        } else {
          return import('@/pages/pc/order/UbiOrderDetail')
        }
      },
      meta: {
        title: '优毕网-优质项目库，一站式指导服务'
      }
    },
    {
      path: '/favorite',
      name: 'favorite',
      component: () => {
        if (isMobile) {
          return import('@/pages/mobile/user/UbiFavorite')
        } else {
          return import('@/pages/pc/user/UbiFavorite')
        }
      },
      meta: {
        title: '优毕网-优质项目库，一站式指导服务'
      }
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => {
        if (isMobile) {
          return import('@/pages/mobile/user/UbiProfile')
        } else {
          return import('@/pages/pc/user/UbiProfile')
        }
      },
      meta: {
        title: '优毕网-优质项目库，一站式指导服务'
      }
    },
    {
      path: '/help',
      name: 'help',
      component: () => {
        if (isMobile) {
          return import('@/pages/mobile/help/UbiHelp')
        } else {
          return import('@/pages/pc/help/UbiHelp')
        }
      },
      meta: {
        title: '优毕网-优质项目库，一站式指导服务'
      }
    },
    {
      path: '/about',
      name: 'about',
      component: () => {
        if (isMobile) {
          return import('@/pages/mobile/about/UbiAbout')
        } else {
          return import('@/pages/pc/about/UbiAbout')
        }
      },
      meta: {
        title: '优毕网-优质项目库，一站式指导服务'
      }
    },
  ]
}

export default options