import Vue from 'vue'
import App from './App.vue'
import {initRouter} from './router'
import {initRUM} from './utils/rum'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'
import store from './store'
import {loadInterceptors} from './utils/requests'
import VueClipboard from 'vue-clipboard2'
import MetaInfo from 'vue-meta-info'

Vue.config.productionTip = false
const router = initRouter()
loadInterceptors(router)

initRUM()

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
Vue.use(Antd)
Vue.use(MetaInfo)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
