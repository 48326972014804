<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
html {
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
}
body {
  overflow-x: hidden;
}
a {
  text-decoration: none;
}
input:focus {
  border: none;
  outline: none;
  -webkit-box-shadow: none;
}
body {
    background: #F3F4F7 !important;
  }
.ant-select-dropdown-menu-item {
  font-size: 16px !important;
  height: 52px !important;
  line-height: 52px !important;
  padding: 0 !important;
  text-align: center;
}


</style>
