export default {
  namespaced: true,
  state: {
    user: undefined
  },
  getters: {
    getUser: state => {
      if (!state.user) {
        try {
          const user = localStorage.getItem(process.env.VUE_APP_USER_KEY)
          state.user = JSON.parse(user)
        } catch (e) {
          console.error(e)
        }
      }
      return state.user
    },
    getUUID: state => {
      if (!state.uuid) {
        try {
          const uuid = localStorage.getItem(process.env.VUE_APP_UUID_KEY)
          state.uuid = JSON.parse(uuid)
        } catch (e) {
          console.error(e)
        }
      }
      return state.uuid
    }
  },
  mutations: {
    setUser (state, user) {
      state.user = user
      localStorage.setItem(process.env.VUE_APP_USER_KEY, JSON.stringify(user))
    },
    setUUID (state, uuid) {
      state.uuid = uuid
      localStorage.setItem(process.env.VUE_APP_UUID_KEY, JSON.stringify(uuid))
    },
    deleteUser (state) {
      state.user = null
      localStorage.removeItem(process.env.VUE_APP_USER_KEY)
    },
  }
}
