import { datafluxRum } from '@cloudcare/browser-rum'

function initRUM() {
  datafluxRum.init({
    applicationId: 'ubi_front_app',
    site: 'https://rum-openway.guance.com',
    clientToken: '2OQwyC4Bvgo8Np10vFIXQlxiCfnqXSNbTSLIGgy1t2vFgG8rOA',
    env: process.env.VUE_APP_ENV,
    version: process.env.VUE_APP_VERSION,
    service: 'ubi-front-app',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackInteractions: true,
    traceType: 'ddtrace',
    allowedTracingOrigins: [/.*/],
  });
  datafluxRum.startSessionReplayRecording()
}

export {initRUM}