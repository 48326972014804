import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/**
 * 初始化路由实例
 * @returns {VueRouter}
 */
function initRouter() {
  const options = require('./config').default
  const router =  new Router(options)
  router.beforeEach((to, from, next) => {
    if (to.meta.title) {
      document.title = to.meta.title
    }
    next()
  })
  const routerPush = router.push
  router.push = path => {
    // 判断下当前路由是否就是要跳转的路由
    if (router.currentRoute.fullPath.includes(path)) return;
    return routerPush.call(router, path);
  }
  return router
}
export {initRouter}